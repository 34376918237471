export const seo = {
  url: 'firma/certyfikaty',
  title: {
    pl: 'Certyfikaty',
    en: 'Certificates',
    ua: 'Сертифікати',
  },
  desc: {
    pl: `Potwierdzeniem jakości naszych usług i wysokich standardów obsługi są zdobyte certyfikaty.`,
    en: `The certificates we have obtained are the confirmation of the quality of our services and high standards of service.`,
    ua: `Отримані нами сертифікати є підтвердженням якості наших послуг та високих стандартів обслуговування.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'certyfikaty', 'tsl'],
}

export const intro = {
  title: {
    pl: 'Certyfikaty',
    en: 'Certificates',
    ua: 'Сертифікати',
  },
  desc: {
    pl: `Potwierdzeniem jakości naszych usług i wysokich standardów obsługi są zdobyte certyfikaty.`,
    en: `The certificates we have obtained are the confirmation of the quality of our services and high standards of service.`,
    ua: `Отримані нами сертифікати є підтвердженням якості наших послуг та високих стандартів обслуговування.`,
  },
}

export const main = {
  title: {
    pl: 'Gwarancja Jakości - Certyfikaty<br/>Omida Sea And Air S.A.',
    en: 'Quality Assurance - Certificates<br/>Omida Sea And Air S.A.',
    ua: 'Гарантія якості - Сертифікати<br/>Omida Sea And Air S.A.',
  },
  body: {
    pl: 'Wszystkie otrzymane certyfikaty są gwarancją naszej dbałości o najwyższy poziom świadczonych usług na każdym etapie ich realizacji.',
    en: 'All received certificates are a guarantee of our care for the highest level of services provided at every stage of their implementation.',
    ua: 'Усі отримані сертифікати є гарантією нашої турботи про найвищий рівень послуг, що надаються на кожному етапі їх реалізації.',
  },
  sections: [
    {
      title: {
        pl: 'Certyfikat FIATA',
        en: 'FIATA Certificate',
        ua: 'Сертифікат FIATA',
      },
      desc: {
        pl: 'Fédération Internationale des Associations de Transitaires et Assimilés - certyfikat ten, reprezentuje i dba o interesy zawodowych spedytorów. Zapewnia również profesjonalny poziom usług świadczonych przez jej członków.',
        en: 'Fédération Internationale des Associations de Transitaires et Assimilés - this certificate represents and cares for the interests of professional forwarders. It also provides a professional level of service provided by its members.',
        ua: 'Fédération Internationale des Associations de Transitaires et Assimilés - цей сертифікат представляє та піклується про інтереси професійних експедиторів. Він також забезпечує професійний рівень послуг, що надаються його членами.',
      },
    },
    {
      title: {
        pl: 'Certyfikat AEO',
        en: 'AEO Certificate',
        ua: 'Сертифікат AEO',
      },
      desc: {
        pl: 'Authorised Economic Operator - jest to dokument potwierdzający wiarygodność przedsiębiorcy w zakresie działań celnych na terenie Unii Europejskiej, pozwala na stosowanie procedur uproszczonych w odprawach celnych.',
        en: 'Authorized Economic Operator - it is a document confirming the credibility of the entrepreneur in the field of customs activities in the European Union, allowing the use of simplified procedures in customs clearance.',
        ua: 'Authorised Economic Operator – це документ, що підтверджує авторитет підприємця у сфері митної діяльності в Європейському Союзі, дозволяє використовувати спрощені процедури митного оформлення.',
      },
    },
    {
      title: {
        pl: 'Certyfikat WSK',
        en: 'WSK Certificate',
        ua: 'Сертифікат WSK',
      },
      desc: {
        pl: 'Certyfikat WSK to dokument potwierdzający posiadanie i stosowanie skutecznego Wewnętrznego Systemu Kontroli wymaganego w firmach uczestniczących w międzynarodowym obrocie produktami podwójnego zastosowania oraz uzbrojeniem.',
        en: 'The WSK Certificate is a document confirming the possession and application of an effective Internal Control System required in companies participating in the international trade in dual-use items and armaments.',
        ua: 'Сертифікат WSK - це документ, що підтверджує наявність та застосування ефективної системи внутрішнього контролю, необхідної для компаній, які беруть участь у міжнародній торгівлі товарами та озброєннями подвійного використання.',
      },
    },
    {
      title: {
        pl: 'Certyfikat IATA',
        en: 'IATA Certificate',
        ua: 'Сертифікат IATA',
      },
      desc: {
        pl: 'International Air Transport Association - certyfikat przyznawany jest przez Międzynarodowe Zrzeszenie Przewoźników Powietrznych.',
        en: 'The certificate is awarded by the International Air Transport Association.',
        ua: 'International Air Transport Association - сертифікат видається Міжнародною асоціацією повітряного транспорту.',
      },
    },
  ],
}
